import {
  RETRIEVE_STATIONS,
  RETRIEVE_STATION,
  UPDATE_STATION,
  CREATE_STATION,
  DELETE_STATION
} from '../actions/types';

const initialState = {
  count: 0,
  elements: []
};

function stationReducer(stations = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_STATION:
      return {
        ...stations,
        count: stations.count + 1,
        elements: [...stations.elements, payload.item]
      };

    case RETRIEVE_STATIONS:
      return {
        ...stations,
        count: payload.count,
        elements: payload.elements
      };

    case RETRIEVE_STATION:
      return {
        ...stations,
        elements: [payload]
      };

    case UPDATE_STATION:
      return {
        count: stations.count,
        elements: stations.elements.map((item) => {
          if (item._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return item;
          }
        })
      };
    case DELETE_STATION:
      return {
        ...stations,
        count: stations.count - 1,
        elements: stations.elements.filter(({ id }) => id !== payload.id)
      };

    default:
      return stations;
  }
}

export default stationReducer;
