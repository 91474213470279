import { configureStore } from '@reduxjs/toolkit';
import stationReducer from '../reducers/stations';

const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    stations: stationReducer
  }
});

export default store;
